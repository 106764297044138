<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
            <b-sidebar id="add_crmcase" aria-labelledby="sidebar-no-header-title" no-header backdrop width="80rem" style="direction:ltr" right title="اضافة عميل" shadow >
                <template #default="{ hide }">
                    <div class="m-1 text-start" style="direction:rtl">
                        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>إضافة عميل</span>
                        </div>
                        <div @click="hide" id="Lmokhide" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                        <span>اغلاق</span>
                    </div>
                </div>
            <v-divider></v-divider>
                <div class="px-3 py-2" style="direction:rtl">
                    <v-row>
                        <v-col cols="12" md="3" sm="12">
                            <label>رقم الجوال</label>
                            <b-form-input class="inborder"
                                label="رقم الجوال"
                                v-model="customers.mobile"
                                @change="getName()"
                                type="number"
                                :rules="mobileRules"
                                >{{ customers.mobile }}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>جوال اضافي</label>
                            <b-form-input class="inborder"
                                label="جوال اضافي"
                                v-model="customers.mobile1"
                                type="number"
                                >{{ customers.mobile1 }}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>اسم العميل</label>
                            <b-form-input class="inborder"
                                label="اسم العميل"
                                v-model="customers.full_name"
                                :rules="nameRules"
                                >{{ customers.full_name }}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>البريد الالكتروني</label>
                            <b-form-input class="inborder"
                                label="البريد الالكتروني"
                                v-model="customers.email"
                                type="email"
                                >{{ customers.email }}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>اسم المنشأة</label>
                            <b-form-input class="inborder"
                                label="اسم المنشأة"
                                v-model="customers.company"
                                type="email"
                                >{{ customers.email }}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>الاسم التجاري</label>
                            <b-form-input class="inborder"
                                label="الاسم التجاري"
                                v-model="customers.trademark"
                                >{{ customers.trademark }}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>رقم السجل</label>
                            <b-form-input class="inborder"
                                label="رقم السجل"
                                v-model="customers.crt_number"
                                >{{ customers.crt_number }}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>الرقم الضريبي</label>
                            <b-form-input class="inborder"
                                label="الرقم الضريبي"
                                v-model="customers.vatid"
                                type="email"
                                >{{ customers.email }}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>اسم العميل</label>
                            <b-input-group>
                            <b-form-select class="selborder"
                                v-model="activity"
                                :options="activities"
                                label="النشاط"
                                ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>نوع الطلب</label>
                            <b-input-group>
                            <b-form-select class="selborder"
                                v-model="systemtype"
                                :options="systemtypes"
                                label="نوع الطلب"
                                ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>توفير أجهزة</label>
                            <b-input-group>
                            <b-form-select class="selborder"
                                v-model="need_computers"
                                :options="yesNo"
                                label="توفير أجهزة"
                                ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>حالة الطلب</label>
                            <b-input-group>
                            <b-form-select class="selborder"
                                v-model="status"
                                :options="orderStatus"
                                label="حالة الطلب"
                                ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>عدد النقاط</label>
                            <b-form-input class="inborder"
                                v-model="posnumebr"
                                type="number"
                                label="عدد النقاط"
                            ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>السعر الاجمالي</label>
                            <b-form-input class="inborder"
                                label="السعر الاجمالي"
                                v-model="full_price"
                                type="email"
                                >{{ customers.email }}</b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>العنوان</label>
                            <b-form-input class="inborder"
                                v-model="customers.address"
                                label="العنوان"
                            ></b-form-input>
                        </v-col>
                        <v-col cols="12" md="3" sm="12">
                            <label>المندوب</label>
                            <b-input-group>
                            <b-form-select class="selborder"
                                v-model="agentid"
                                :options="agents"
                                label="المندوب"
                                ></b-form-select>
                                <b-input-group-addon style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-addon>
                            </b-input-group>
                        </v-col>
                    </v-row>
                </div>
                </template>
                <template #footer="" class="shadow">
                    <div class="d-flex text-light align-items-center px-3 py-2">
                        <b-button type="button" @click="addCRM();" variant="success" class="ma-2" style="width:100px;">إضافة</b-button>
                    </div>
            </template>
            </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import axios from 'axios';
export default{
    data: () => ({
        fullrows: [],
        agentid: 0,
        full_price: 0,
        agents: [],
        activity: 1,
        posnumebr: 1,
        status: 1,
        // orderStatus: [
        //     { text: 'جديد',  value: 1},
        //     { text: 'التفاوض ',  value: 2},
        //     { text: 'تركيب ديمو ',  value: 3},
        //     { text: 'الاتصال بالعميل',  value: 4},
        //     { text: 'تم السداد ',  value: 5},
        //     { text: 'تم الغاء الطلب ',  value: 6},
        // ],
        systemtype: 9,
        // yesNo: [
        //     { text: 'نعم',  value: 1},
        //     { text: 'لا',  value: 2},
        // ],
        need_computers: 9,
        // systemtypes: [
        //     { text: 'كلاود',  value: 1},
        //     { text: 'تحميل على الجهاز',  value: 2},
        // ],
        // activities: [
        //     { text: 'صيانة السيارات',  value: 1},
        //     { text: 'بناشر',  value: 2},
        //     { text: 'مغسلة ملابس',  value: 3},
        //     { text: 'بقالات',  value: 4},
        //     { text: 'حلاقين',  value: 5},
        //     { text: 'بوفيات',  value: 6},
        //     { text: 'قطاع طبي',  value: 7},
        //     { text: 'قطاع مقاولات',  value: 8},
        //     { text: 'قطع غيار',  value: 9},
        //     { text: 'أخرى',  value: 10},
        // ],
        customers:{
            mobile: '',
            mobile1: '',
            full_name: '',
            email: '',
            company: '',
            vatid: '',
            id: 0,
            address: '',
            trademark:'',
            crt_number: '',
        },
        mobileRules: [
            v => !!v || 'رقم الجوال مطلوب',
            v => (v && v.length <= 10) || 'رقم الجوال يجب ان يكون عشر ارقام',
        ],
        nameRules: [
            v => !!v || 'اسم العميل مطلوب',
        ],
    }),
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        activities: function() {
            let x = [];
            if(this.fullrows.length == 0) return [];
            for(let i = 0;i < this.fullrows[1].length;i++){
                let xx = {
                    text: this.$parent.lang.langname == "ar" ? this.fullrows[1][i].namear : this.fullrows[1].nameen,
                    value: this.fullrows[1][i].id,
                }
                x.push(xx);
            }
            return x;
        },
        yesNo: function() {
            let x = [];
            if(this.fullrows.length == 0) return [];
            for(let i = 0;i < this.fullrows[0].length;i++){
                let xx = {
                    text: this.$parent.lang.langname == "ar" ? this.fullrows[0][i].namear : this.fullrows[0].nameen,
                    value: this.fullrows[0][i].id,
                }
                x.push(xx);
            }
            return x;
        },
        systemtypes: function() {
            let x = [];
            if(this.fullrows.length == 0) return [];
            for(let i = 0;i < this.fullrows[2].length;i++){
                let xx = {
                    text: this.$parent.lang.langname == "ar" ? this.fullrows[2][i].namear : this.fullrows[2].nameen,
                    value: this.fullrows[2][i].id,
                }
                x.push(xx);
            }
            return x;
        },
        orderStatus: function() {
            let x = [];
            if(this.fullrows.length == 0) return [];
            for(let i = 0;i < this.fullrows[3].length;i++){
                let xx = {
                    text: this.$parent.lang.langname == "ar" ? this.fullrows[3][i].namear : this.fullrows[3].nameen,
                    value: this.fullrows[3][i].id,
                }
                x.push(xx);
            }
            return x;
        }
    },
    created() {
        this.getAgents();
        this.getAsettings();
        
    },
    methods: {
        getAsettings(){
            const post = new FormData();
            post.append("type","getCRMSettigns");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]",'1');
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log("----",res.results.data);
                this.fullrows = res.results.data;
            });
        },
        addCRM(){
            if(!this.$refs.form.validate()){
                return false;
            }
            const post = new FormData();
            post.append('type','addCRM');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            for(const [key, value] of Object.entries(this.customers)){
                post.append('data[customers]['+key+']]',value);
            }
            post.append("data[agentid]",this.agentid);
            post.append("data[activity]",this.activity);
            post.append("data[posnumebr]",this.posnumebr);
            post.append("data[status]",this.status);
            post.append("data[systemtype]",this.systemtype);
            post.append("data[need_computers]",this.need_computers);
            post.append("data[full_price]",this.full_price);
            axios.post(
                this.$SAMCOTEC.r_path, post 
            ).then((response) => {
                // // console.log("its added",response.data);
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                 this.$snotify.success('تمت اضافة العميل بنجاح', 'اضافة عميل', {
                        timeout: 2000,
                        showProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        leftTop:"leftTop"
                    });
                let custo = response.data.results.data.deal;
                custo.id = response.data.results.data.dID;
                this.$parent.getCustomers();
                this.customers = {
                    mobile: '',
                    mobile1: '',
                    full_name: '',
                    email: '',
                    company: '',
                    vatid: '',
                    id: 0
                }
                document.getElementById('Lmokhide').click();
            })
        },
        getName(){
            const post = new FormData();
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customers.mobile);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                const res = response.data;
                if(res.error.number == 200){
                    this.customers.id = res.results.data['id'];
                    this.customers.full_name = res.results.data['full_name'];
                }
                }
            )
        },
        getAgents(){
            const post = new FormData();
            post.append("type","getAgents");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.agents = res.results.data.agents;
                this.agentid = res.results.data.myID;
            });
        }
    },
}
</script>